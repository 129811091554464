import { render, staticRenderFns } from "./FiltersModal.vue?vue&type=template&id=0cbd80ee&scoped=true&lang=pug"
import script from "./FiltersModal.vue?vue&type=script&lang=js"
export * from "./FiltersModal.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0cbd80ee",
  null
  
)

export default component.exports